<template>
  <b-overlay :show="loading" rounded="sm">
    <Layout>
      <template #scheduling-layer>
        <StaffLayer
          :active-layer="activeLayer"
          :layers="layers"
          @change-active-layer="changeActiveLayer"
        />
      </template>

      <template #header>
        <Header
          :date="date"
          :search-term="searchTerm"
          :active-layer-populated="activeLayerPopulated"
          :is-mobile-view="isMobile"
          @change-date="changeDate"
        />
      </template>

      <template #content>
        <Content
          ref="content"
          :date="date"
          :active-layer-index="activeLayerIndex"
          :calendar="calendar"
          :calendar-cache="calendarCache"
          :active-layer-populated="activeLayerPopulated"
          :availability="availability"
          :employees="employees"
          :service-providers="serviceProviders"
          :create-pool="createPool"
          :update-pool="updatePool"
          :delete-pool="deletePool"
          :search-term="searchTerm"
          :work-time-adjustments="workTimeAdjustments"
          :layers="layers"
          :can-update="canUpdate"
          :drag-context="dragContext"
          :active-layer="activeLayer"
          :is-admin="isAdmin"
          @change-layer-index="changeLayerIndex"
          @update-work-time="updateWorkTime"
          @force-update="forceUpdate"
          @update-calendar="updateCalendar"
          @fill-rest="fillRest"
          @action-click-start="changeDragContext"
          @reset-drag-context="resetDragContext"
          @change-search-term="changeSearchTerm"
          @transmit="toTransmit"
          @change-date="changeDate"
        />
      </template>
    </Layout>
  </b-overlay>
</template>

<script>
import moment from 'moment'
import unsavedChanges from '@/utility/scripts/unsavedChanges'
import { BOverlay } from 'bootstrap-vue'
import Layout from './mobile/layout.vue'
import Content from './mobile/mobileContent/content.vue'
import Header from './header.vue'
import StaffLayer from './mobile/staffLayer.vue'

import {
  initialLoading,
  load,
  loadCalendar,
  loadEmployeesAvailability,
  updateCalendar,
  loadEmployees,
  loadAbsences,
  loadEmployeesDesiredCount,
} from '../utility/load'

export default {
  components: {
    StaffLayer,
    BOverlay,
    Layout,
    Content,
    Header,
  },

  data: () => ({
    isAdmin: false,

    loading: false,
    date: moment().startOf('day').valueOf(),
    searchTerm: '',
    isMobile: true,
    activeLayer: null,
    activeLayerIndex: 0,
    calendar: [],
    calendarCache: {},

    availability: [],
    availabilityCache: {},

    absences: [],
    absencesCache: {},

    dragContext: null,
    createPool: [],
    updatePool: [],
    deletePool: [],
    workTimeAdjustments: {},
    employees: [],
    employeesCache: {},
    serviceProviders: [],
    layers: [],
    stack: [],
    desiredCountCache: {},
  }),

  computed: {
    activeLayerPopulated() {
      if (this.activeLayer === null) return null
      return this.layers.find(x => x.id === this.activeLayer)
    },
    canUpdate() {
      return (
        this.createPool.length !== 0 ||
        this.updatePool.length !== 0 ||
        this.deletePool.length !== 0
      )
    },
  },

  watch: {
    canUpdate(value) {
      window.onbeforeunload = value ? () => 'confirm' : null
    },
  },

  async created() {
    this.isAdmin = this.$can(
      this.$acl.action.Update,
      this.$acl.subjects.StaffScheduling,
    )
    await this.initialLoading()
  },

  methods: {
    changeLayerIndex(newIndex) {
      this.activeLayerIndex = newIndex
    },
    async wannaLeave() {
      if (this.canUpdate) {
        return await unsavedChanges(this)
      }
      return true
    },

    async changeDate(value) {
      this.loading = true
      this.date = value
      await Promise.all([
        this.loadCalendar(),
        this.loadEmployeesAvailability(),
        this.loadEmployees(),
        this.loadEmployeesDesiredCount(),
      ])
      this.loading = false
    },

    changeSearchTerm(value) {
      this.searchTerm = value
    },

    updateWorkTime(updateArray) {
      updateArray.forEach(x => {
        if (this.workTimeAdjustments[x.key] === undefined) {
          this.workTimeAdjustments[x.key] = x.value
        } else {
          this.workTimeAdjustments[x.key] += x.value
        }
      })

      const copy = JSON.parse(JSON.stringify(this.workTimeAdjustments))
      this.workTimeAdjustments = {}
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.workTimeAdjustments = copy
        })
      })
    },

    fillRest(serviceProvider) {
      this.$refs.content.fillRest(serviceProvider)
    },

    forceUpdate() {
      const copy = JSON.parse(JSON.stringify(this.calendar))
      this.calendar = copy
    },

    async changeActiveLayer(value) {
      this.activeLayerIndex = 0
      this.loading = true
      this.activeLayer = value
      await Promise.all([
        this.loadCalendar(),
        this.loadEmployeesAvailability(),
        this.loadAbsences(),
      ])
      this.loading = false
    },

    async initialLoading() {
      return initialLoading.call(this)
    },

    async loadEmployeesDesiredCount() {
      return loadEmployeesDesiredCount.call(this)
    },

    async load(...args) {
      return load.call(this, ...args)
    },

    async loadCalendar() {
      return loadCalendar.call(this)
    },

    async loadEmployeesAvailability() {
      return loadEmployeesAvailability.call(this)
    },

    async loadAbsences() {
      return loadAbsences.call(this)
    },

    async updateCalendar() {
      return updateCalendar.call(this)
    },

    async loadEmployees() {
      return loadEmployees.call(this)
    },

    async toExport(...args) {
      const { toExport } = await import('../utility/actions')
      return toExport.call(this, ...args)
    },

    async toTransmit(...args) {
      const { toTransmit } = await import('../utility/actions')
      return toTransmit.call(this, ...args)
    },

    changeDragContext(context) {
      this.dragContext = context
    },

    resetDragContext() {
      this.dragContext = null
    },

    openStack() {},
  },

  async beforeRouteUpdate(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  async beforeRouteLeave(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },
}
</script>
