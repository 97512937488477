import moment from 'moment'

export async function loadOrders() {
  const { data } = await this.$axios.get('orders')
  this.orders = data
}

export async function refreshNoCache() {
  this.isLoading = true
  await this.load(true)
  await this.loadCalendar(true)
}

export async function load(noCache = false) {
  const { data } = await this.$axios.get('layers/layers-for-notice-of-need', {
    headers: { cache: noCache ? 'no-cache' : 'cache' },
  })

  this.responsibleLayers = data.reduce((prev, curr) => {
    const unit = {
      id: curr.id,
      open: true,
      indeterminate: false,
      name: curr.area,
      lines: curr.configuration.lines.map(x => ({
        name: x.name,
        active: true,
      })),
      layers: curr.configuration.layers.map(x => x.name),
      config: curr.configuration,
      department: curr.department,
    }

    return { ...prev, [curr.id]: unit }
  }, {})
}

export async function loadCalendar(noCache = false) {
  this.isLoading = true
  try {
    const { data } = await this.$axios.get(
      `notice-of-need-calendar/calendar/${moment(this.date).valueOf()}`,
      { headers: { cache: noCache ? 'no-cache' : 'cache' } },
    )

    this.calendarUntouched = JSON.parse(JSON.stringify(data))
    this.calendar = []

    this.$nextTick(() => {
      this.$nextTick(() => {
        this.calendar = this.calcCalender(data)
      })
    })
  } catch {
    this.$alert.error()
  }
  this.isLoading = false
}

export async function updateCalendar(alert = true) {
  this.isLoading = true

  try {
    const { data } = await this.$axios.put(
      `notice-of-need-calendar/calendar/${moment(this.date).valueOf()}`,
      {
        createPool: this.createCalendarPool,
        updatePool: this.updateCalendarPool,
        ordersLayerBooked: this.ordersLayerBookedUpdate,
      },
    )
    this.createCalendarPool = []
    this.updateCalendarPool = []
    this.ordersLayerBookedUpdate = []

    await this.loadOrders()

    this.calendarUntouched = JSON.parse(JSON.stringify(data))
    this.calendar = this.calcCalender(data)

    const subject = this.$t('NotificationOfNeed.NoticeOfNeedFor', {
      timeSpan: `${this.weekStart} - ${this.weekEnd}`,
    })
    if (alert) {
      this.$alert.success(this.$t('Global.SuccessUpdate', { subject }))
    }
  } catch {
    this.$alert.error()
  }
  this.isLoading = false
}
