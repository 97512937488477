import confirmPopup from '@/utility/scripts/confirm'
import moment from 'moment'

export default async function approveWeek() {
  const wannaApprove = await confirmPopup(this, {
    okText: this.$t('Global.Approve'),
    title: this.$t('Global.Approve'),
    text: this.$t('NotificationOfNeed.ApproveText', {
      time: `${this.weekStart} - ${this.weekEnd}`,
    }),
  })

  if (!wannaApprove) return

  if (
    this.createCalendarPool.length !== 0 ||
    this.updateCalendarPool.length !== 0
  ) {
    const choice = await confirmPopup(this, {
      okText: this.$t('Global.Save'),
      title: this.$t('Global.Save'),
      text: this.$t('NotificationOfNeed.UnsavedChangesText'),
    })

    if (!choice) return
    await this.updateCalendar(false)
  }

  this.isLoading = true
  try {
    const toApprove = []
    const keys = Object.keys(this.responsibleLayers)

    keys.forEach(key => {
      const layer = this.responsibleLayers[key]
      if (layer.open === false) return

      layer.lines.forEach((line, i) => {
        if (line.active) {
          toApprove.push({ layer: key, lineIndex: i })
        }
      })
    })

    await this.$axios.patch(
      `notice-of-need-calendar/approve-calendar/${moment(this.date).valueOf()}`,
      toApprove,
    )
    await this.loadCalendar()
    this.$alert.success(
      this.$t('NotificationOfNeed.UpdateApprove', {
        startDate: this.weekStart,
        endDate: this.weekEnd,
      }),
    )
  } catch (error) {
    this.$alert.error()
  }
  this.isLoading = false
}
